<template>
  <div class="d-flex">
    <div class="px-7 py-0 rounded-xl logo-container">
      <!-- logo of the app -->
      <v-img :src="require('../assets/logo.png')"
             height="100"
             width="200"
             contain
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {Component} from "vue-property-decorator";

@Component
export default class LoginComponent extends Vue {

}
</script>

<style scoped>

</style>